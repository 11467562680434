(function(){function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s}return e})()({1:[function(require,module,exports){
jQuery(function($) {
    $('.c-products__child').click(function(e) {
        $('#product-tab-1').hide();
        $('#product-tab-2').hide();
        $('#product-tab-3').hide();
        $('#product-tab-4').hide();
        $('.c-products__child').removeClass('c-products__child--dark');

        $('#' + $(this).data('tab')).show();
        $(this).addClass('c-products__child--dark');
    });
});
},{}]},{},[1]);
